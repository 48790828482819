import React from 'react'
import { Container } from 'react-bootstrap'
import Header from './kit/Header'
import { Button } from './kit/Button'
import NavTabs from './NavTabs'
import Spacer from './kit/Spacer'

export function NavSection() {
  return (
    <>
      <Header>
        <Button icon="BsMoonFill" text="" type="transparent"></Button>
      </Header>
      <Spacer size={56} />
      <Container>
        <NavTabs />
      </Container>
    </>
  )
}
