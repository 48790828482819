import io from 'socket.io-client'
let socket
let usersCount = 0

export function initiateSocket() {
  // socket = io('http://localhost:3000')
  if (!socket) {
    socket = io()
    usersCount = 0
    console.log(`Connecting socket...`)
  }
  usersCount++
  return
}

export function disconnectSocket() {
  usersCount--
  if (socket && usersCount <= 0) {
    console.log('Disconnecting socket...')
    socket.disconnect()
    socket = null
    usersCount = 0
  }
}

export function subscribe(method, cb) {
  if (!socket) return true
  socket.on(method, cb)
}

export function unSubscribe(method, cb) {
  if (!socket) return true
  socket.off(method, cb)
}

// export function subscribeToCommands(cb) {
//   return subscribe(METHODS.command, cb)
// }

// export function subscribeToMessageUpdateStatus(cb) {
//   return subscribe(METHODS.messageUpdateStatus, cb)
// }

// export function subscribeToConcurrentViewers(cb) {
//   return subscribe(METHODS.concurrentViewers, cb)
// }

export function subscribeToTweetsReady(cb) {
  return subscribe(SOCKET_METHODS.tweetsReady, cb)
}
export function unSubscribeFromTweetsReady(cb) {
  return unSubscribe(SOCKET_METHODS.tweetsReady, cb)
}

export const SOCKET_METHODS = {
  command: 'command',
  collectUserPictures: 'collectUserPictures',
  messageUpdateStatus: 'messageUpdateStatus',
  newComment: 'newComment',
  concurrentViewers: 'concurrentViewers',
  collectedPicturesCount: 'collectedPicturesCount',
  voteStatus: 'voteStatus',
  tweetsReady: 'tweetsReady',


  youtubeConfigurationChanged: 'youtubeConfigurationChanged',
  twitchConfigurationChanged: 'twitchConfigurationChanged',
  tournamentIdConfigurationChanged: 'tournamentIdConfigurationChanged',
  gameNumberConfigurationChanged: 'gameNumberConfigurationChanged',
  voteTagsConfigurationChanged: 'voteTagsConfigurationChanged',

  voteStatusChanged: 'voteStatusChanged',
  collectingUserPicturesStatusChanged: 'collectingUserPicturesStatusChanged',
  displayTweets: 'displayTweets',
  tweetsStateChanged: 'tweetsStateChanged',
  newTweetReady: 'newTweetReady',
}
