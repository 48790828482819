import { Container } from 'react-bootstrap'
import { useParams } from 'react-router'

import { useQuery } from '../hooks'
import { ConfigurationWrapper } from '../components/configurations/ConfigurationWrapper'
import { ConfigurationNotFound } from '../components/configurations/ConfigurationNotFound'
import { EventConfigurations } from '../components/configurations/EventConfigurations'
import { YoutubeConfig } from '../components/configurations/YoutubeConfig'
import { TwitchConfig } from '../components/configurations/TwitchConfig'
import { TournamentIdConfig } from '../components/configurations/TournamentIdConfig'

function getContentByType(type, isShowNextButton = false) {
  switch (type) {
    case 'youtube':
      return (
        <ConfigurationWrapper
          head1="Create new event"
          head2="Step 1. Add YouTube"
          isShowNextButton={isShowNextButton}
          prevLink="/configuration"
          nextLink="/configuration/twitch"
          isFirstLink
        >
          <YoutubeConfig submitButtonText={isShowNextButton ? 'Next' : 'Save'} redirectAfterSubmition={isShowNextButton ? '/configuration/twitch' : '/configuration'} />
        </ConfigurationWrapper>
      )
    case 'twitch':
      return (
        <ConfigurationWrapper
          head1="Create new event"
          head2="Step 2. Add Twitch"
          isShowNextButton={isShowNextButton}
          nextLink="/configuration/tournament"
          prevLink="/configuration/youtube"
        >
          <TwitchConfig submitButtonText={isShowNextButton ? 'Next' : 'Save'} redirectAfterSubmition={isShowNextButton ? '/configuration/tournament' : '/configuration'} />
        </ConfigurationWrapper>
      )
    case 'tournament':
      return (
        <ConfigurationWrapper
          head1="Create new event"
          head2="Step 3. Add TournamentID"
          isShowNextButton={isShowNextButton}
          nextLink="/configuration"
          prevLink="/configuration/twitch"
          isLastLink 
        >
          <TournamentIdConfig submitButtonText={isShowNextButton ? 'Next' : 'Save'} redirectAfterSubmition={isShowNextButton ? '/configuration' : '/configuration'} />
        </ConfigurationWrapper>
      )
    default:
      return null
  }
}

export function ConfigurationPage() {
  const { type } = useParams()
  const query = useQuery()
  const isShowNextButton = !!query.get('show_next')
  // console.log('isShowNextButton', isShowNextButton);
  const configurationContent = getContentByType(type, isShowNextButton)
  const content = configurationContent ? configurationContent : <EventConfigurations />
  const contentBase = type && !configurationContent ? <ConfigurationNotFound type={type} /> : content

  return <Container>{contentBase}</Container>
}
