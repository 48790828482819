export function getDurationTimeString(duration) {
  const daysModifier = 1000 * 60 * 60 * 24
  const hoursModifier = 1000 * 60 * 60
  const minutesModifier = 1000 * 60
  const secondsModifier = 1000
  let days = Math.floor(duration / daysModifier)
  let hours = Math.floor((duration - days * daysModifier) / hoursModifier)
  let minutes = Math.floor((duration - days * daysModifier - hours * hoursModifier) / minutesModifier)
  let seconds = Math.floor(
    (duration - days * daysModifier - hours * hoursModifier - minutes * minutesModifier) / secondsModifier
  )

  if (days < 10) {
    days = '0' + days
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  const daysS = days && days > 0 ? `${days} d. ` : ''
  const hoursS = hours && hours > 0 ? `${hours} h. ` : ''
  const minutesS = minutes && minutes > 0 ? `${minutes} m. ` : ''
  const secondsS = seconds && seconds > 0 ? `${seconds} s.` : '0 s.'
  return `${daysS}${hoursS}${minutesS}${secondsS}`
}
