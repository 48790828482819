import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'

import { getTitrosConfig } from '../../getTitrosConfig'
import { TitroSection } from './TitroSection'

export const TitroPanel = () => {
  const [titroConfig, setTitroConfig] = useState({})
  useEffect(() => {
    setTitroConfig(getTitrosConfig)
  }, [])
  return (
    <Row>
      {titroConfig?.sections?.map((sectionConfig, key) => (
        <TitroSection key={key} {...sectionConfig} />
      ))}
    </Row>
  )
}
