import styled from 'styled-components'
import { Form, InputGroup } from 'react-bootstrap'
import { TextInput } from './TextInput'
import { FieldArray, Formik } from 'formik'
import { Button } from './Button'
import Spacer from './Spacer'
import React from 'react'
import { useEffect } from 'react'
import { TextAreaInput } from './TextAreaInput'
import { H4, H5 } from './Headings'
import { ButtonsBlock } from './ButtonsBlock'

const StyledStringInputGroup = styled.div`
  border: ${({ border }) => (border ? '1px solid #ced4da' : '')};
  padding: ${({ border }) => (border ? '24px' : '')};
  box-sizing: border-box;
  border-radius: 4px;
`

const StyledInputGroup = styled(InputGroup)`
  color: ${({ theme }) => theme.colors.textInput};
`

export function StringsForm({
  readonly = false,
  border = true,
  label = '',
  keepSaveVisibleIfReadonly = false,
  handleSubmit = () => {},
  placeholder = 'string',
  addButtonText = 'Add string',
  submitButtonText = 'Submit',
  emptyLabel = '',
  defaultValues = [''],
  isStreched = false,
  isManagebleStringsCount = false,
  canDeleteLast = true,
  useTextAreas = false,
  validationSchema,
  validateOnChange = false,
  validateOnBlur = false,
  enableReinitialize = false,
  children,
  ...rest
}) {
  const isArr = Array.isArray(defaultValues)
  !isArr && console.error(`defaultValues: ${JSON.stringify(defaultValues)} is broken`)
  let processedValues = isArr ? defaultValues : []
  processedValues = processedValues.filter((string) => {
    const res = typeof string == 'string'
    !res && console.error(`defaultValues: ${JSON.stringify(defaultValues)} is broken 2`)
    return res
  })
  const TextComponent = useTextAreas ? TextAreaInput : TextInput
  // Name for inner variables array
  const name = 'strings'
  // console.log(validationSchema);

  useEffect(() => {
    console.log('String form rerender')
  })
  return (
    <StyledStringInputGroup border={border}>
      {label && <H5>{label}</H5>}
      <Formik
        {...rest}
        enableReinitialize={enableReinitialize}
        initialValues={{ strings: processedValues }}
        validationSchema={validationSchema}
        validateOnChange={validateOnChange}
        validateOnBlur={validateOnBlur}
        onSubmit={(values) => {
          handleSubmit(values.strings)
          console.log('Submited form with values: ', JSON.stringify(values, null, 2))
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting, submitCount }) => (
          <>
            <Form
              onSubmit={(e) => {
                // prevent page reload
                e.preventDefault()
                handleSubmit()
              }}
            >
              <FieldArray
                name={name}
                render={(arrayHelpers) => (
                  <div>
                    {emptyLabel && (!values || !values[name] || values[name].length < 1) && <H4>{emptyLabel}</H4>}
                    {values[name].map((string, key) => {
                      const error =
                        touched && touched[name] && touched[name][key] && errors && errors[name] && errors[name][key] // actually error
                      const inputName = `${name}.${key}`
                      const isDeleteButtonVisible =
                        isManagebleStringsCount && (canDeleteLast || values[name].length > 1)
                      return (
                        <React.Fragment key={key}>
                          <StyledInputGroup>
                            <TextComponent
                              key={key}
                              name={inputName}
                              isWrappedInGroup={false}
                              placeholder={placeholder}
                              disabled={readonly}
                            />
                            {!readonly && isDeleteButtonVisible && (
                              <Button
                                text=""
                                type="danger"
                                icon="BsTrash"
                                onClick={() => {
                                  arrayHelpers.remove(key)
                                }}
                              >
                                {/* <img src="./images/svg/trash.svg"></img> */}
                              </Button>
                            )}
                          </StyledInputGroup>
                          {error && (
                            <>
                              <Spacer size={12} />
                              <p className="field-error">{error}</p>
                            </>
                          )}
                          {key < values[name].length - 1 && <Spacer size={24} />}
                        </React.Fragment>
                      )
                    })}
                    {(!readonly || keepSaveVisibleIfReadonly) && (
                      <>
                        <Spacer size={32} />
                        <ButtonsBlock isStreched={isStreched}>
                          {isManagebleStringsCount && (
                            <Button
                              text={addButtonText}
                              disabled={readonly}
                              icon="BsPlusLg"
                              onClick={() => arrayHelpers.push('')}
                            ></Button>
                          )}
                          <Button submit disabled={readonly} text={submitButtonText}></Button>
                        </ButtonsBlock>
                      </>
                    )}
                  </div>
                )}
              />
            </Form>
            {children && <Spacer size={12} />}
            {children}
          </>
        )}
      </Formik>
    </StyledStringInputGroup>
  )
}
