import { youtubeFormSchema } from '../../code/validators'
import { getYoutubeLinks, setYoutubeLinks } from '../../services/api.service'
import { SOCKET_METHODS } from '../../services/socket.service'
import { StringsConfigurationWidget } from './StringsConfigurationWidget'

export function YoutubeConfig({ readonly, redirectAfterSubmition, submitButtonText = 'Save' }) {
  return (
    <StringsConfigurationWidget
      readonly={readonly}
      border={!readonly}
      redirectAfterSubmition={redirectAfterSubmition}
      submitButtonText={submitButtonText}
      emptyLabel="No streams"
      validationSchema={youtubeFormSchema}
      valuesChangeEventName={SOCKET_METHODS.youtubeConfigurationChanged}
      handleSubmit={async (strings) => {
        // alert(strings)
        await setYoutubeLinks(strings)
      }}
      getInitialValues={async () => {
        return await getYoutubeLinks()
        // return ['https://www.youtube.com/watch?v=21lfTYSqRqc', 'https://www.youtube.com/watch?v=21lfTYSqRqc']
      }}
      getValuesFromResponce={({ streams }) => {
        return (streams || []).map((s) => s.url)
      }}
    />
  )
}
