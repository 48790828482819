import React from 'react'
import { Col } from 'react-bootstrap'

import { socketCommand } from '../../services/api.service'
import { Button } from '../kit/Button'

export function TitroButton({ command, label, size = 4, offset = 0, color = 'success', ...rest }) {
  return (
    <Col xs={{ span: size, offset }}>
      <Button color={color} fluid {...rest} onClick={() => socketCommand({ command, values: {} })}>
        {label}
      </Button>
    </Col>
  )
}
