import { ConfigurationPage, InfoPage, TwitterPage, LogsPage, ServicesPage, TitroPage } from '../pages'

const getRouteString = (url, param) => (param ? `${url}/${param}` : `${url}`)
// {/* <PrivateRoute exact path={['/', '/home']} component={HomePage} /> */}
// {/* <Route exact path="/login" component={Login} /> */}
// {/* <Route exact path="/register" component={Register} /> */}
const ROUTES = [
  {
    label: 'CONFIGURATION',
    component: ConfigurationPage,
    getRoute: (type = '') => getRouteString(`/configuration`, type),
    paths: ['/configuration/:type?'],
    exact: true,
    title: 'Settings',
    isPrivate: false,
  },
  {
    label: 'SERVICES',
    component: ServicesPage,
    getRoute: (type = '') => getRouteString(`/services`, type),
    paths: ['/services/:type?'],
    exact: true,
    title: 'Services',
    isPrivate: false,
  },
  {
    label: 'TITRO',
    component: TitroPage,
    getRoute: () => `/titro-panel`,
    paths: ['/', '/titro-panel'],
    exact: true,
    title: 'Titros',
    isPrivate: false,
  },
  {
    label: 'TWITTER',
    component: TwitterPage,
    getRoute: () => `/twitter`,
    paths: ['/twitter'],
    exact: true,
    title: 'Twitter',
    isPrivate: false,
  },
  // {
  //   label: 'INFO',
  //   component: InfoPage,
  //   getRoute: (type = '') => getRouteString(`/info`, type),
  //   paths: ['/info/:type'],
  //   exact: true,
  //   title: 'Info',
  //   isPrivate: false,
  // },
  // {
  //   label: 'LOGS',
  //   component: LogsPage,
  //   getRoute: () => `/logs`,
  //   paths: ['/logs'],
  //   exact: true,
  //   title: 'Логи',
  //   isPrivate: false,
  // },
]

export default ROUTES
