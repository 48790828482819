import React from 'react'
import styled from 'styled-components'
const StyledBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.infoBg};
  border: 1px solid ${({ theme }) => theme.colors.infoBorder};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  > p {
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap; /* Opera <7 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
  }
`

export default function InfoBlock({ children }) {
  return <StyledBlock>{children}</StyledBlock>
}
