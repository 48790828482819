export async function post(url, data) {
  return await (await runRequest(url, data, 'POST')).json()
}

export async function get(url) {
  return await (await runEmptyRequest(url, 'GET')).json()
}

async function runRequest(url, data, type) {
  return (
    url &&
    (await fetch(url, {
      method: type,
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data && JSON.stringify(data),
    }))
  )
}

async function runEmptyRequest(url, type) {
  return (
    url &&
    (await fetch(url, {
      method: type,
    }))
  )
}
