import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { useSearch } from '../../hooks'
import { gameNumberFormScheme, voteTagsFormScheme } from '../../code/validators'
import {
  getGameNumber,
  getVoteState,
  getVoteTags,
  sendVoteResultToApp,
  setGameNumber,
  setVoteTags,
  startVote,
  updateVoteTagsFromSLTV,
  stopVote,
  sendSheetResult,
} from '../../services/api.service'
import { SOCKET_METHODS } from '../../services/socket.service'
import { getStatusMessageFromStatus } from '../../code/getStatusMessageFromStatus'
import { StringsConfigurationWidget } from '../configurations/StringsConfigurationWidget'

import { Button } from '../kit/Button'
import { ButtonsBlock } from '../kit/ButtonsBlock'
import { H2, H5 } from '../kit/Headings'
import Spacer from '../kit/Spacer'
import { useSocket } from '../../hooks/useSocket'
import { VoteResult } from './VoteResult'
import { useVoteDurationAlert } from '../../hooks/useVoteDurationAlert'

// const unrealStatusColors = {
//   ok: 'green',
//   error: 'red',
//   normal: 'white',
// }
// function getUnrealColorFromStatus(status) {
//   return unrealStatusColors[status] || 'white'
// }

export function VoteService({ updateStatusTimerMS = 2000 }) {
  const [status, setStatus] = useState(false)
  // const [tournamentId, setTournamentId] = useState(0)
  // const [tags, setTags] = useState([])

  const [voteObject, setVoteObject] = useState({})
  const [results, setResults] = useState({})
  const [top, setTop] = useState([])
  const [playbleIds, setPlaybleIds] = useState([])
  const [persents, setPersents] = useState([])
  //   setExampleState({...exampleState,  masterField2: {
  //     fieldOne: "a",
  //     fieldTwo: {
  //        fieldTwoOne: "b",
  //        fieldTwoTwo: "c"
  //        }
  //     },
  // })
  // const [unrealStatus, setUnrealStatus] = useState({
  //   status: 'normal',
  //   message: '',
  // })

  const history = useHistory()
  const search = useSearch()

  async function getVoteServiceStatus() {
    const { isRunning, results, playbleIds, top, persents, vote } =
      await getVoteState()
    setStatus(!!isRunning) // to bool
    setResults(results)
    setPlaybleIds(playbleIds)
    setPersents(persents)
    setTop(top)
    setVoteObject(vote)
  }
  // async function getTags() {
  //   const { tags } = await getVoteTags()
  //   setTags(tags) // to bool
  // }

  useEffect(() => {
    getVoteServiceStatus()
  }, [])

  // useVoteDurationAlert(voteObject, !status, 10 * 1000, 20 * 1000)
  useVoteDurationAlert(voteObject, !status)

  // useEffect(() => {
  //   getTags()
  // }, [])

  useEffect(() => {
    let timer
    if (status) {
      timer = setInterval(async () => {
        await getVoteServiceStatus()
      }, updateStatusTimerMS)
    }
    return () => {
      timer && clearInterval(timer)
    }
  }, [status, updateStatusTimerMS])

  useSocket(
    SOCKET_METHODS.voteStatusChanged,
    (values) => values.status,
    (data) => getVoteServiceStatus(),
    (newStatus) => setStatus(!!newStatus)
  )

  const matchNumberWidget = useMemo(
    () => (
      <StringsConfigurationWidget
        label='Game number'
        useTextAreas={true}
        keepSaveVisibleIfReadonly={true}
        isManagebleStringsCount={false}
        submitButtonText='Save'
        placeholder='gameNumber'
        readonly={status}
        validationSchema={gameNumberFormScheme}
        valuesChangeEventName={SOCKET_METHODS.gameNumberConfigurationChanged}
        handleSubmit={async (strings) => {
          // alert(strings)
          await setGameNumber(strings[0])
        }}
        getInitialValues={async () => {
          return await getGameNumber() || ''
          // return ['3663']
        }}
        getValuesFromResponce={({ number }) => {
          return [number]
        }}
      />
    ),
    [status]
  )
  const tagsWidget = useMemo(
    () => (
      <StringsConfigurationWidget
        label='Vote tags'
        useTextAreas={true}
        keepSaveVisibleIfReadonly={true}
        readonly={status}
        isManagebleStringsCount={false}
        submitButtonText='Save'
        placeholder='tag1,tag2,tag3'
        validationSchema={voteTagsFormScheme}
        valuesChangeEventName={SOCKET_METHODS.voteTagsConfigurationChanged}
        handleSubmit={async (strings) => {
          // alert(strings)
          await setVoteTags(strings[0])
          // await getTags()
        }}
        getInitialValues={async () => {
          return await getVoteTags()
        }}
        getValuesFromResponce={({ tags }) => {
          return [tags.join(',')]
        }}
      >
        <ButtonsBlock>
          <Button
            disabled={status}
            onClick={async () => {
              const { tags } = await updateVoteTagsFromSLTV()
              setVoteTags(tags)
            }}
          >
            Insert sltv tags
          </Button>
          <Button
            disabled={status}
            onClick={() =>
              history.push({ pathname: '/services/custom-vote-result', search })
            }
          >
            Custom vote result form
          </Button>
        </ButtonsBlock>
      </StringsConfigurationWidget>
    ),
    [status, history, search]
  )
  return (
    <div>
      <H2>Vote</H2>
      <Spacer size={8} />
      <H5>Status: {getStatusMessageFromStatus(status)}</H5>
      <Spacer size={8} />
      <VoteResult
        voteState={{ persents, playbleIds, results, top, vote: voteObject }}
      />
      <Spacer size={32} />
      {tagsWidget}
      <Spacer size={8} />
      {matchNumberWidget}
      <Spacer size={32} />
      <ButtonsBlock>
        <Button
          disabled={status}
          onClick={async () => {
            setStatus(true)
            await startVote()
          }}
          forseDisableDelay={800}
        >
          Start vote
        </Button>
        <Button
          disabled={!status}
          forseDisableDelay={800}
          onClick={async () => {
            setStatus(false)
            await stopVote()
          }}
        >
          Stop vote
        </Button>
        <Button
          onClick={async () => {
            await sendVoteResultToApp()
          }}
        >
          Send vote result to app
        </Button>
        <Button
          onClick={() =>
            history.push({ pathname: '/services/vote-history', search })
          }
        >
          Vote history
        </Button>
        {/* <Button
          onClick={async () =>
            await sendSheetResult()
          }
        >
          Send Result From Spreadsheet 
        </Button> */}
      </ButtonsBlock>
    </div>
  )
}
