import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { InputLabel } from './InputLabel'
import { Field } from 'formik'

const StyledFormControl = styled(Form.Control)`
  color: ${({ theme }) => theme.colors.textInput};
  min-height: 25px;
  max-height: 250px;
  ${({ fluid }) => fluid ? `width: 100%;` : ''};

  &::placeholder {
    color: ${({ theme }) => theme.colors.textInputPlaceholder};
  }

  &.disabled,
  &:disabled,
  &[readonly] {
    background-color: ${({ theme }) => theme.colors.textInputPlaceholder};
  }
`

function StyledFormControlWrapper(props) {
  return <StyledFormControl as="textarea" {...props} />
}

export function TextAreaInput({
  label = '',
  fluid=true,
  isWrappedInGroup = true,
  placeholder = 'placeholder',
  disabled = false,
  ...rest
}) {
  const content = (
    <>
      {label && <InputLabel text={label} />}
      <Field disabled={disabled} placeholder={placeholder} fluid={fluid} as={StyledFormControlWrapper} {...rest} />
    </>
  )
  const result = isWrappedInGroup ? <Form.Group>{content}</Form.Group> : content
  return result
}
