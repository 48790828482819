import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { InputLabel } from './InputLabel'
import { Field } from 'formik'

const StyledCheck = styled(Form.Check)`
  & > input {
    width: ${({ size }) => size || 20}px;
    height: ${({ size }) => size || 20}px;
    margin-top: 0;
  }
  & > input.checked,
  & > input:checked,
  & > input[checked] {
    background-color: ${({ theme }) => theme.colors.checkboxInputBg};
    border-color: ${({ theme }) => theme.colors.checkboxInputBg};
  }
  & > input:focus {
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.checkboxInputFocusShadow};
    border-color: ${({ theme }) => theme.colors.checkboxInputFocusBorder};
  }

  & > input.disabled,
  & > input:disabled,
  & > input[readonly] {
    background-color: ${({ theme }) => theme.colors.checkboxInputInactiveBg};
    border-color: ${({ theme }) => theme.colors.checkboxInputInactiveBg};
  }
`

export function CheckboxInput({ label = '', isWrappedInGroup = true, disabled = false, useFormik = true, ...rest }) {
  const content = (
    <>
      {label && <InputLabel text={label} />}
      {useFormik && <Field disabled={disabled} type="checkbox" as={StyledCheck} {...rest} />}
      {!useFormik && <StyledCheck disabled={disabled} type="checkbox" {...rest} />}
    </>
  )
  const result = isWrappedInGroup ? <Form.Group>{content}</Form.Group> : content
  return result
}
