import { useHistory } from 'react-router'
import { H2 } from '../kit/Headings'
import Spacer from '../kit/Spacer'
import { Button } from '../kit/Button'

export function ReadonlyConfigurationWrapper({ label, children, link }) {
  const history = useHistory()
  return (
    <div>
      {label && <H2>{label}</H2>}
      <Spacer size={32} />
      {children}
      <Spacer size={32} />
      <Button type="secondary" text="Edit" onClick={() => history.push(link)}></Button>
    </div>
  )
}
