import styled from 'styled-components'
import { Form, InputGroup } from 'react-bootstrap'
import { TextInput } from './TextInput'
import { FieldArray, Formik } from 'formik'
import { Button } from './Button'
import Spacer from './Spacer'
import React from 'react'
import { useEffect } from 'react'
import { TextAreaInput } from './TextAreaInput'
import { H4, H5 } from './Headings'
import { ButtonsBlock } from './ButtonsBlock'
import { useMemo } from 'react'

const StyledStringInputGroup = styled.div`
  border: ${({ border }) => (border ? '1px solid #ced4da' : '')};
  padding: ${({ border }) => (border ? '24px' : '')};
  box-sizing: border-box;
  border-radius: 4px;
`

const StyledInputGroup = styled(InputGroup)`
  color: ${({ theme }) => theme.colors.textInput};
`

const PairedInputContainer = styled.div`
  display: flex;
`

export function PairedStringsForm({
  readonly = false,
  tupleStringCount = 2,
  border = true,
  isStreched = false,
  label = '',
  handleSubmit = () => {},
  placeholder = 'string',
  addButtonText = 'Add string pair',
  submitButtonText = 'Submit',
  emptyLabel = 'Трансляций нет',
  defaultValues = [{ key1: '', key2: '' }],
  isManagebleStringsCount = false,
  canDeleteLast = true,
  useTextAreas = false,
  validationSchema,
  validateOnChange = false,
  validateOnBlur = false,
  enableReinitialize = false,
  children,
  ...rest
}) {
  // validate
  // console.log(defaultValues);
  const isArr = Array.isArray(defaultValues)
  // console.log(isArr);
  !isArr && console.error(`defaultValues: ${JSON.stringify(defaultValues)} is broken`)
  let processedValues = isArr ? defaultValues : []
  processedValues = processedValues.filter((obj) => {
    let result = true
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        // eslint-disable-next-line
        const element = obj[key]
        result = typeof element == 'string'
      }
    }
    !result && console.error(`defaultValues: ${JSON.stringify(defaultValues)} is broken`)
    return result
  })
  const defaultValuesObject = useMemo(() => {
    const res = defaultValues[0] || { key1: '', key2: '' }
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        res[key] = ''
      }
    }
    return res
  }, [defaultValues])

  // Init
  const name = 'result'
  const initailsValues = {}
  initailsValues[name] = processedValues
  const TextComponent = useTextAreas ? TextAreaInput : TextInput
  // Name for inner variables array
  // console.log(validationSchema);

  useEffect(() => {
    console.log('String tuple form rerender')
  })
  return (
    <StyledStringInputGroup border={border}>
      {label && <H5>{label}</H5>}
      <Formik
        {...rest}
        enableReinitialize={enableReinitialize}
        initialValues={initailsValues}
        validationSchema={validationSchema}
        validateOnChange={validateOnChange}
        validateOnBlur={validateOnBlur}
        onSubmit={(values) => {
          // Call handleSubmit passed to PairedStringsForm
          handleSubmit(values[name])
          console.log('Submited form with values: ', JSON.stringify(values, null, 2))
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting, submitCount }) => (
          <>
            <Form
              onSubmit={(e) => {
                // prevent page reload
                e.preventDefault()
                // Call handleSubmit (onSubmit) passed to Form
                handleSubmit()
              }}
            >
              <FieldArray
                name={name}
                render={(arrayHelpers) => (
                  <div>
                    {emptyLabel && (!values || !values[name] || values[name].length < 1) && <H4>{emptyLabel}</H4>}
                    {values[name].map((valuesObject, key) => {
                      //console.log(valuesObject)
                      const errorsObject =
                        touched && touched[name] && touched[name][key] && errors && errors[name] && errors[name][key]
                      const inputGroupName = `${name}.${key}`
                      const isDeleteButtonVisible =
                        isManagebleStringsCount && (canDeleteLast || values[name].length > 1)
                      const keysArray = Object.keys(valuesObject)
                      //console.log(keysArray)
                      return (
                        <React.Fragment key={key}>
                          <PairedInputContainer>
                            {keysArray &&
                              keysArray.map((inputNameKey, key) => {
                                const error = errorsObject && errorsObject[inputNameKey]
                                return (
                                  <React.Fragment key={key}>
                                    <StyledInputGroup>
                                      <TextComponent
                                        key={key}
                                        name={`${inputGroupName}.${inputNameKey}`}
                                        isWrappedInGroup={false}
                                        placeholder={inputNameKey}
                                        disabled={readonly}
                                      />
                                    </StyledInputGroup>
                                    {error && (
                                      <>
                                        <Spacer size={12} />
                                        <p className="field-error">{error}</p>
                                      </>
                                    )}
                                  </React.Fragment>
                                )
                              })}
                            {!readonly && isDeleteButtonVisible && (
                              <Button
                                text=""
                                type="danger"
                                icon="BsTrash"
                                onClick={() => {
                                  arrayHelpers.remove(key)
                                }}
                              >
                                {/* <img src="./images/svg/trash.svg"></img> */}
                              </Button>
                            )}
                          </PairedInputContainer>

                          {key < values[name].length - 1 && <Spacer size={24} />}
                        </React.Fragment>
                      )
                    })}
                    {!readonly && (
                      <>
                        <Spacer size={32} />
                        <ButtonsBlock isStreched={isStreched}>
                          {isManagebleStringsCount && (
                            <Button
                              text={addButtonText}
                              icon="BsPlusLg"
                              onClick={() => arrayHelpers.push(defaultValuesObject)}
                            ></Button>
                          )}
                          <Button submit text={submitButtonText}></Button>
                        </ButtonsBlock>
                      </>
                    )}
                  </div>
                )}
              />
            </Form>
            {children && <Spacer size={12} />}
            {children}
          </>
        )}
      </Formik>
    </StyledStringInputGroup>
  )
}
