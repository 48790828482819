import { post, get } from '../code/utils'

export async function socketCommand(args) {
  console.log('socketCommand', args)
  return await post('/api/socketCommand', { args })
}

///////////
// Configuration
// validate
export async function getYoutubeLinkInfo(link) {
  return await post('/api/getYoutubeLinkInfo', { link })
}
export async function getTwitchLinkInfo(link) {
  return await post('/api/getTwitchLinkInfo', { link })
}
export async function getTournamentIdInfo(tournamentId) {
  return await post('/api/getTournamentIdInfo', { tournamentId })
}
// get
export async function getTwitchLinks() {
  return await get('/api/getTwitchLinks')
}
export async function getYoutubeLinks() {
  return await get('/api/getYoutubeLinks')
}
export async function getTournamentId() {
  return await get('/api/getTournamentId')
}
//set
export async function setTwitchLinks(links) {
  return await post('/api/setTwitchLinks', { links })
}
export async function setYoutubeLinks(links) {
  return await post('/api/setYoutubeLinks', { links })
}
export async function setTournamentId(tournamentId) {
  return await post('/api/setTournamentId', { tournamentId })
}
//
///////////

///////////
// Services

// vote
export async function getVoteTags() {
  return await get('/api/getVoteTags')
}
export async function setVoteTags(tags) {
  return await post('/api/setVoteTags', { tags })
}
export async function startVote() {
  return await post('/api/startVote')
}
export async function stopVote() {
  return await post('/api/stoptVote')
}
export async function getVoteState() {
  return await get('/api/getVoteState')
}
export async function updateVoteTagsFromSLTV() {
  return await post('/api/updateVoteTagsFromSLTV')
}
export async function sendVoteResultToApp() {
  return await post('/api/sendVoteResultToApp')
}
// export async function getVoteResult() {
//   return await post('/api/getVoteResult')
// }
// export async function getLastVoteResult() {
//   return await post('/api/getLastVoteResult')
// }
export async function sendCustomVoteResult(result) {
  return await post('/api/sendCustomVoteResult', { result })
}
export async function getVoteHistory() {
  return await get('/api/getVoteHistory')
}
export async function sendSheetResult() {
  return await post('/api/sendSheetResult')
}
//

// GameNumber
export async function setGameNumber(number) {
  return await post('/api/setGameNumber', { number })
}
export async function getGameNumber() {
  return await get('/api/getGameNumber')
}
//

// Avatars
export async function startCollectingUserPictures() {
  return await post('/api/startCollectingUserPictures')
}
export async function stopCollectingUserPictures() {
  return await post('/api/stopCollectingUserPictures')
}
export async function getCollectingUserPicturesStatus() {
  return await post('/api/getCollectingUserPicturesStatus')
}
export async function sendUserPicturesToApp(count = 8000) {
  return await post('/api/sendUserPicturesToApp', { count })
}
export async function getCollectedPicturesCount() {
  return await post('/api/getCollectedPicturesCount')
}
// export async function getCollectingUserPicturesTags() {
//   return await post('/api/getCollectingUserPicturesTags')
// }
// export async function setCollectingUserPicturesTags(tags) {
//   return await post('/api/setCollectingUserPicturesTags', { tags })
// }
//

// twitter
export async function getTwitterMessages() {
  return await post('/api/getTwitterMessages')
}

export async function getTweets() {
  return await get('/api/getTweets')
}
export async function syncTwitter() {
  return await post('/api/syncTwitter')
}
export async function setTweetSelected(id, isSelected) {
  return await post('/api/setTweetSelected', { id, isSelected })
}
export async function sendTweetsToApp() {
  return await post('/api/sendTweetsToApp')
}
export async function deleteTweet(id) {
  return await post('/api/deleteTweet', { id })
}
//

// winner
export async function getWinner() {
  return await post('/api/getWinner')
}
//

//
///////////

// export async function setYoutubeVideoId(videoId) {
//   return await post('/api/setYoutubeVideoId', { videoId })
// }
// export async function setTwitchChannels(channels) {
//   return await post('/api/setTwitchChannels', { channels })
// }
// export async function getYoutubeVideoId() {
//   return await post('/api/getYoutubeVideoId')
// }
// export async function getTwitchChannels() {
//   return await post('/api/getTwitchChannels')
// }

// //count
// export async function collectViewersCount() {
//   return await post('/api/collectViewersCount')
// }
// export async function sendCountToApp() {
//   return await post('/api/sendCountToApp')
// }
// export async function getConcurrentViewersCount() {
//   return await post('/api/getConcurrentViewersCount')
// }

// // comments
// export async function startCollectingComments() {
//   return await post('/api/startCollectingComments')
// }
// export async function setCollectingCommentsTags(tags) {
//   return await post('/api/setCollectingCommentsTags', { tags })
// }
// export async function stopCollectingComments() {
//   return await post('/api/stoptCollectingComments')
// }
// export async function getCommentsCollectingStatus() {
//   return await post('/api/getCommentsCollectingStatus')
// }
// export async function getCommentsCollectingTags() {
//   return await post('/api/getCommentsCollectingTags')
// }
// export async function getComments(()count) {
//   return await post('/api/getComments', { count })
// }
// //
