import React, { useEffect, useState } from 'react'
import {
  getCollectingUserPicturesStatus,
  startCollectingUserPictures,
  stopCollectingUserPictures,
  sendUserPicturesToApp,
} from '../../services/api.service'
import { SOCKET_METHODS } from '../../services/socket.service'
import { Button } from '../kit/Button'
import { ButtonsBlock } from '../kit/ButtonsBlock'
import { H2, H5 } from '../kit/Headings'
import InfoBlock from '../kit/InfoBlock'
import Spacer from '../kit/Spacer'
import { getStatusMessageFromStatus } from '../../code/getStatusMessageFromStatus'
import { useSocket } from '../../hooks/useSocket'

export function AvatarsService({ updateStatusTimerMS = 2000 }) {
  const [status, setStatus] = useState(false)
  const [count, setCount] = useState(0)

  async function getAvatarsStatus() {
    const { isRunning, count } = await getCollectingUserPicturesStatus()
    setStatus(!!isRunning) // to bool
    setCount(count)
  }

  useEffect(() => {
    getAvatarsStatus()
  }, [])

  useEffect(() => {
    let timer
    if (status) {
      timer = setInterval(async () => {
        await getAvatarsStatus()
      }, updateStatusTimerMS)
    }
    return () => {
      timer && clearInterval(timer)
    }
  }, [status, updateStatusTimerMS])

  useSocket(
    SOCKET_METHODS.collectingUserPicturesStatusChanged,
    (values) => values.status,
    (data) => getAvatarsStatus(),
    (newStatus) => setStatus(!!newStatus)
  )

  return (
    <div>
      <H2>Avatars collection</H2>
      <Spacer size={8} />
      <H5>Status: {getStatusMessageFromStatus(status)}</H5>
      <Spacer size={8} />
      <InfoBlock>
        <p>Collected avatars count: {count}</p>
      </InfoBlock>

      <Spacer size={32} />
      <ButtonsBlock>
        <Button
          disabled={status}
          onClick={async () => {
            setStatus(true)
            await startCollectingUserPictures()
          }}
          forseDisableDelay={800}
        >
          Start collect
        </Button>
        <Button
          disabled={!status}
          forseDisableDelay={800}
          onClick={async () => {
            setStatus(false)
            await stopCollectingUserPictures()
          }}
        >
          Stop collect
        </Button>
        <Button
          onClick={async () => {
            await sendUserPicturesToApp()
          }}
        >
          Send avatars to app
        </Button>
      </ButtonsBlock>
    </div>
  )
}
