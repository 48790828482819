import React from 'react'
import { sendCustomVoteResult } from '../../services/api.service'
import { PairedStringsForm } from '../kit/PairedStringsForm'

export function CustomVoteResult() {
  function convertStringsFormResultToVoteResult(values) {
    const result = {}
    for (let i = 0; i < values.length; i++) {
      // eslint-disable-next-line
      const { tag, count } = values[i]
      result[tag.trim().toLowerCase()] = parseInt(count.trim().toLowerCase())
    }
    return result
  }
  return (
    <PairedStringsForm
      label={'Set custom vote result'}
      useTextAreas={false}
      readonly={false}
      border={!false}
      isStreched={true}
      placeholder={'hi'}
      addButtonText={'Add new vote tag'}
      submitButtonText={'Send vote result'}
      defaultValues={[{ tag: '', count: 0 }]}
      isManagebleStringsCount={true}
      canDeleteLast={false}
      handleSubmit={async (values) => {
        await sendCustomVoteResult(convertStringsFormResultToVoteResult(values))
        // redirectAfterSubmition && history.push(redirectAfterSubmition)
      }}
    ></PairedStringsForm>
  )
}
