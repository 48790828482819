import { twitchFormSchema } from '../../code/validators'
import { getTwitchLinks, setTwitchLinks } from '../../services/api.service'
import { SOCKET_METHODS } from '../../services/socket.service'
import { StringsConfigurationWidget } from './StringsConfigurationWidget'

export function TwitchConfig({ readonly, redirectAfterSubmition, submitButtonText = 'Save' }) {
  return (
    <StringsConfigurationWidget
      readonly={readonly}
      border={!readonly}
      redirectAfterSubmition={redirectAfterSubmition}
      validationSchema={twitchFormSchema}
      submitButtonText={submitButtonText}
      emptyLabel="No streams"
      valuesChangeEventName={SOCKET_METHODS.twitchConfigurationChanged}
      handleSubmit={async (strings) => {
        // alert(strings)
        await setTwitchLinks(strings)
      }}
      getInitialValues={async () => {
        return await getTwitchLinks()
        // return ['https://www.youtube.com/watch?v=21lfTYSqRqc', 'https://www.youtube.com/watch?v=21lfTYSqRqc']
      }}
      getValuesFromResponce={({ streams }) => {
        return streams.map((s) => s.url)
      }}
    />
  )
}
