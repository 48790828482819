import styled from 'styled-components'
import { H2 } from '../kit/Headings'
import { Tweet } from './Tweet'

const MessagesWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2vw;
`
export function TweetsWrapper({ tweets, title, onTweetStateChanged, isSelectedGroup }) {
  return (
    <div>
      {title && <H2>{title}</H2>}
      <MessagesWrapper>
        {tweets &&
          tweets.map((tweet, key) => (
            <Tweet
              key={key}
              isSelected={isSelectedGroup}
              index={key}
              onStateChanged={onTweetStateChanged}
              tweet={tweet}
            />
          ))}
      </MessagesWrapper>
    </div>
  )
}
