import React, { useEffect, useMemo, useState } from 'react'
import { Switch, useHistory } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import 'dayjs/locale/en' // load on demand
import * as dayjs from 'dayjs'
import * as localizedFormat from 'dayjs/plugin/localizedFormat'

import { ThemeProvider } from 'styled-components'
import 'alertifyjs/build/css/alertify.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/reset.css'
import './styles/main.css'

import themes from './styles/themes'

import ROUTES from './config/routes'
import { Page } from './pages'

import { NavSection } from './components/NavSection'
import { Alerts } from './components/Alerts'
import { MyRoute } from './services/auth/MyRoute'
import Spacer from './components/kit/Spacer'
import { FirstTimeConfiguration } from './components/FirstTimeConfiguration'
import { getBrowserLocales } from './code/getBrowserLocales'

export default function App() {
  const history = useHistory()
  const [theme] = useState('defaultTheme')
  // const [theme, setTheme] = useState('defaultTheme')
  const routes = useMemo(() => {
    return ROUTES.map(({ label, component, paths, exact, title, isPrivate }, key) => (
      <MyRoute
        isPrivate={isPrivate}
        key={key}
        exact={exact}
        path={paths}
        render={(props) => <Page {...props} component={component} title={title} label={label} />}
      />
    ))
  }, [])
  useEffect(() => {
    console.log('App rerender')
  })
  useEffect(() => {
    dayjs.extend(localizedFormat)
    dayjs.locale('en')
    console.log(getBrowserLocales())
  }, [])
  return (
    <BrowserRouter history={history}>
      <ThemeProvider theme={themes[theme]}>
        <FirstTimeConfiguration />
        <NavSection />
        <Alerts />
        <Spacer size={40} />
        <Switch>{routes}</Switch>
        <Spacer size={100} />
      </ThemeProvider>
    </BrowserRouter>
  )
}
