import React from 'react'

import { TitroForm } from './TitroForm'
import { TitroButtonsBlock } from './TitroButtonsBlock'

export function TitroBlock({ type, ...rest }) {
  switch (type) {
    case 'form':
      return <TitroForm {...rest} />
    case 'buttons':
      return <TitroButtonsBlock {...rest} />
    default:
      break
  }
  return null
}
