import { Container } from 'react-bootstrap'
import { TwitterService } from '../components/services/TwitterService'

export function TwitterPage() {
  return (
    <Container>
      <TwitterService />
    </Container>
  )
}
