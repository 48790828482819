import Spacer from '../kit/Spacer'

import { VoteService } from './VoteService'
import { AvatarsService } from './AvatarsService'

export function ServicesList() {
  return (
    <>
      <VoteService />
      <Spacer size={80} />
      <AvatarsService />
    </>
  )
}
