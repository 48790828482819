import { useEffect, useState } from 'react'
import { Button as BSButton } from 'react-bootstrap'
import styled from 'styled-components'
import { Icon } from './Icon'

const StyledBSButton = styled(BSButton)`
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  ${({ fluid }) => fluid && 'width: 100%;'}
  svg {
    ${({ text }) => `${text ? 'margin-right: 6px;' : ''};`}
  }
  &.disabled,
  &:disabled {
    color: ${({ theme }) => theme.colors.btnInactive};
    background-color: ${({ theme }) => theme.colors.btnInactiveBg};
    border-color: ${({ theme }) => theme.colors.btnInactive};
  }
`
const StyledPrimary = styled(StyledBSButton)`
  color: ${({ theme }) => theme.colors.btnPrimary};
  background-color: ${({ theme }) => theme.colors.btnPrimaryBg};
  border-color: ${({ theme }) => theme.colors.btnPrimary};

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.btnPrimaryHover};
    background-color: ${({ theme }) => theme.colors.btnPrimaryBgHover};
    border-color: ${({ theme }) => theme.colors.btnPrimaryHover};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.btnPrimaryHover};
    background-color: ${({ theme }) => theme.colors.btnPrimaryBgHover};
    border-color: ${({ theme }) => theme.colors.btnPrimaryHover};
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.btnPrimaryBgHover}50;
  }
  &:active:focus {
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.btnPrimaryBgHover}50;
  }
`
const StyledSecondary = styled(StyledBSButton)`
  color: ${({ theme }) => theme.colors.btnSecondary};
  background-color: ${({ theme }) => theme.colors.btnSecondaryBg};
  border-color: ${({ theme }) => theme.colors.btnSecondary};

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.btnSecondaryHover};
    background-color: ${({ theme }) => theme.colors.btnSecondaryBgHover};
    border-color: ${({ theme }) => theme.colors.btnSecondaryHover};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.btnSecondaryHover};
    background-color: ${({ theme }) => theme.colors.btnSecondaryBgHover};
    border-color: ${({ theme }) => theme.colors.btnSecondaryHover};
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.btnSecondaryBgHover}50;
  }
  &:active:focus {
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.btnSecondaryBgHover}50;
  }
`

const StyledThird = styled(StyledBSButton)`
  color: ${({ theme }) => theme.colors.btnThird};
  background-color: ${({ theme }) => theme.colors.btnThirdBg};
  border-color: ${({ theme }) => theme.colors.btnThird};

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.btnThirdHover};
    background-color: ${({ theme }) => theme.colors.btnThirdBgHover};
    border-color: ${({ theme }) => theme.colors.btnThirdHover};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.btnThirdHover};
    background-color: ${({ theme }) => theme.colors.btnThirdBgHover};
    border-color: ${({ theme }) => theme.colors.btnThirdHover};
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.btnThirdBgHover}50;
  }
  &:active:focus {
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.btnThirdBgHover}50;
  }
`

const StyledDanger = styled(StyledBSButton)`
  color: ${({ theme }) => theme.colors.btnDanger};
  background-color: ${({ theme }) => theme.colors.btnDangerBg};
  border-color: ${({ theme }) => theme.colors.btnDanger};

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.btnDangerHover};
    background-color: ${({ theme }) => theme.colors.btnDangerBgHover};
    border-color: ${({ theme }) => theme.colors.btnDangerHover};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.btnDangerHover};
    background-color: ${({ theme }) => theme.colors.btnDangerBgHover};
    border-color: ${({ theme }) => theme.colors.btnDangerHover};
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.btnDangerBgHover}50;
  }
  &:active:focus {
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.btnDangerBgHover}50;
  }
`

const StyledTransparent = styled(StyledBSButton)`
  color: ${({ theme }) => theme.colors.btnTransparent};
  background-color: transparent;
  border: 0;

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.btnTransparentHover};
    background-color: transparent;
    border: 0;
  }
  &:focus {
    color: ${({ theme }) => theme.colors.btnTransparentHover};
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  &:active:focus {
    box-shadow: none;
  }
`

const StyledTransparentInverted = styled(StyledBSButton)`
  color: ${({ theme }) => theme.colors.btnTransparentHover};
  background-color: transparent;
  border: 0;

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.btnTransparent};
    background-color: transparent;
    border: 0;
  }
  &:focus {
    color: ${({ theme }) => theme.colors.btnTransparent};
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  &:active:focus {
    box-shadow: none;
  }
`

const buttonTypes = {
  primary: StyledPrimary,
  secondary: StyledSecondary,
  third: StyledThird,
  danger: StyledDanger,
  transparent: StyledTransparent,
  transparentInverted: StyledTransparentInverted,
}

export function Button({
  icon = null,
  children,
  text = '',
  type = 'primary',
  submit = false,
  disabled = false,
  fluid = false,
  forseDisableDelay = 0,
  onClick = async () => {
    return true
  },
  ...rest
}) {
  const [forseDisable, setForseDisable] = useState(false)
  useEffect(() => {
    if (forseDisable) {
      setTimeout(() => {
        setForseDisable(false)
      }, forseDisableDelay)
    }
  }, [forseDisable, forseDisableDelay])
  // eslint-disable-next-line
  const StyleComponent = buttonTypes[type] || buttonTypes['primary']
  return (
    <StyleComponent
      disabled={disabled}
      fluid={fluid ? 'true' : undefined}
      icon={icon}
      text={text}
      type={submit ? 'submit' : ''}
      onClick={async () => {
        if (!forseDisable && forseDisableDelay && forseDisableDelay > 0) setForseDisable(true)
        !forseDisable && (await onClick())
      }}
      {...rest}
    >
      {icon && <Icon type={icon} />}
      {text}
      {children}
    </StyleComponent>
  )
}
