import { Container } from 'react-bootstrap'

import { TitroPanel } from '../components/titro/TitroPanel'

export function TitroPage() {
  return (
    <Container fluid>
      {/* <StringInputGroupTest /> */}
      {/* <TitroPanel /> */}
      {/* <InputTest /> */}
      {/* <TextAreaTest /> */}
      {/* <ButtonsTest /> */}
      {/* <TextAreaTest2 /> */}
      <TitroPanel />
    </Container>
  )
}
// function StringInputGroupTest() {
//   return (
//     <>
//       <StringsForm
//         placeholder='url'
//         addButtonText='Добавить трансляцию'
//         submitButtonText='Далее'
//         // defaultValues={['']}
//         defaultValues={['https://www.youtube.com/watch?v=21lfTYSqRqc','https://www.youtube.com/watch?v=21lfTYSqRqc']}
//         isManagebleStringsCount
//         handleSubmit={async (strings) => {
//           // alert(data)
//           await setTwitchLinks(strings)
//         }}></StringsForm>
//       <Spacer size={80} />
//     </>
//   )
// }
// function TextAreaTest() {
//   const options = [
//     '1',
//     '2',
//     '3',
//     '4',
//     '5',
//   ]
//   return (
//     <>
//       <SelectInput />
//       <Spacer size={8} />
//       <SelectInput disabled />
//       <Spacer size={8} />

//       <SelectInput empty />
//       <Spacer size={8} />
//       <SelectInput empty disabled />
//       <Spacer size={8} />

//       <SelectInput options={options}/>
//       <Spacer size={8} />
//       <SelectInput options={options} disabled/>
//       <Spacer size={8} />

//       <SelectInput empty options={options}/>
//       <Spacer size={8} />
//       <SelectInput empty options={options} disabled/>
//       <Spacer size={8} />

//       <SelectInput label='test' />
//       <Spacer size={8} />
//       <SelectInput label='test' disabled />
//       <Spacer size={8} />

//       <SelectInput label='test' empty />
//       <Spacer size={8} />
//       <SelectInput label='test' empty disabled />
//       <Spacer size={8} />

//       <SelectInput label='test' options={options}/>
//       <Spacer size={8} />
//       <SelectInput label='test' options={options} disabled/>
//       <Spacer size={8} />

//       <SelectInput label='test' empty options={options}/>
//       <Spacer size={8} />
//       <SelectInput label='test' empty options={options} disabled/>
//       <Spacer size={8} />
//       <Spacer size={80} />
//     </>
//   )
// }
// function TextAreaTest2() {
//   return (
//     <>
//       <TextAreaInput />
//       <TextAreaInput type='password'/>
//       <Spacer size={8} />
//       <TextAreaInput type='email'/>
//       <Spacer size={8} />
//       <TextAreaInput textarea/>
//       <Spacer size={8} />
//       <TextAreaInput />
//       <Spacer size={8} />
//       <TextAreaInput />
//       <Spacer size={8} />
//       <TextAreaInput disabled />
//       <Spacer size={8} />
//       <TextAreaInput value='test' disabled />
//       <Spacer size={8} />

//       <Spacer size={80} />
//     </>
//   )
// }
// function InputTest() {
//   return (
//     <>
//       <TextInput />
//       <TextInput type='password'/>
//       <Spacer size={8} />
//       <TextInput type='email'/>
//       <Spacer size={8} />
//       <TextInput textarea/>
//       <Spacer size={8} />
//       <TextInput />
//       <Spacer size={8} />
//       <TextInput />
//       <Spacer size={8} />
//       <TextInput disabled />
//       <Spacer size={8} />
//       <TextInput value='test' disabled />
//       <Spacer size={8} />

//       <Spacer size={80} />
//     </>
//   )
// }

// function ButtonsTest() {
//   return (
//     <>
//       <Button />
//       <Spacer size={8} />
//       <Button />
//       <Spacer size={8} />
//       <Button />
//       <Spacer size={8} />
//       <Button fluid />
//       <Button disabled />
//       <Button icon="BsPlus" />
//       <Button disabled icon="BsPlus" />
//       <Spacer size={10} />

//       <Button type="secondary" />
//       <Spacer size={8} />
//       <Button type="secondary" />
//       <Spacer size={8} />
//       <Button type="secondary" />
//       <Spacer size={8} />
//       <Button type="secondary" fluid />
//       <Button type="secondary" disabled />
//       <Button type="secondary" icon="BsPlus" />
//       <Button type="secondary" disabled icon="BsPlus" />
//       <Spacer size={10} />

//       <Button type="third" />
//       <Spacer size={8} />
//       <Button type="third" />
//       <Spacer size={8} />
//       <Button type="third" />
//       <Spacer size={8} />
//       <Button type="third" fluid />
//       <Button type="third" disabled />
//       <Button type="third" icon="BsPlus" />
//       <Button type="third" disabled icon="BsPlus" />
//       <Spacer size={10} />
//     </>
//   )
// }
