import React, { useMemo } from 'react'
import * as dayjs from 'dayjs'

import { H2 } from '../kit/Headings'
import InfoBlock from '../kit/InfoBlock'
import Spacer from '../kit/Spacer'
import { getIsEndedMessage } from '../../code/getIsEndedMessage'
import { getVoteDurationString } from '../../code/getVoteDurationString'

export function VoteResult({
  displayHead = false,
  displayStatus = false,
  displayWholeVote = false,
  displayTags = false,
  voteState: { results, top, persents, vote, playbleIds },
}) {
  const voteDurationString = useMemo(() => getVoteDurationString(vote), [vote])
  return (
    <div>
      {displayHead && (
        <H2>
          Vote: {vote.gameNumber}:{vote.id}
        </H2>
      )}
      <InfoBlock>
        {displayStatus && (
          <>
            <p>Status: {getIsEndedMessage(vote?.isEnded)}</p>
            <Spacer size={10} />
          </>
        )}
        {displayTags && vote.tags && (
          <>
            <p>Tags: {JSON.stringify(vote?.tags)}</p>
            <Spacer size={10} />
          </>
        )}
        {displayWholeVote && (
          <>
            <p>vote: {JSON.stringify(vote)}</p>
            <Spacer size={10} />
          </>
        )}
        <p>Game number: {vote?.gameNumber}</p>
        <Spacer size={10} />
        <p>Vote start time: {dayjs(vote?.startedAt).format('LL LTS')}</p>
        <Spacer size={10} />
        <p>Vote duration: {voteDurationString}</p>
        <Spacer size={10} />

        {vote?.endedAt && (
          <>
            <p>Vote end time: {dayjs(vote?.endedAt).format('LL LTS')}</p>
            <Spacer size={10} />
          </>
        )}
        <p>Result: {JSON.stringify(results)}</p>
        <Spacer size={10} />
        {playbleIds && (
          <>
            <p>To unrial (with commands ids): {JSON.stringify(playbleIds)}</p>
            <Spacer size={10} />
          </>
        )}
        <p>Top 3: {JSON.stringify(top)}</p>
        <Spacer size={10} />
        <p>Persents: {JSON.stringify(persents)}</p>
      </InfoBlock>
    </div>
  )
}
