import alertify from 'alertifyjs'
import { useEffect, useRef, useState } from 'react'
import { getVoteDuration } from '../code/getVoteDuration'
import { getVoteDurationString } from '../code/getVoteDurationString'

export function useVoteDurationAlert(
  vote,
  disabled = false,
  minimumTimeToNotifyMS = 1000 * 60 * 60,
  minimumDelayBetweenNotifyMS = 1000 * 60 * 10
) {
  const delayTimer = useRef(null)
  const [enabled, setEnabled] = useState(true)

  function cleanUp() {
    delayTimer.current && clearTimeout(delayTimer.current)
  }
  
  useEffect(() => {
    const voteDuration = getVoteDuration(vote)
    if (voteDuration > minimumTimeToNotifyMS) {
      if (enabled && !disabled) {
        setEnabled(false)
        delayTimer.current = setTimeout(() => {
          setEnabled(true)
          delayTimer.current = null
        }, minimumDelayBetweenNotifyMS)
        alertify.notify(`Your vote is going for: ${getVoteDurationString(vote)}. Maybe you forget to turn it off!`, 'warning', 1000 * 60 * 2)
      }
    } else {
      setEnabled(true)
      cleanUp()
    }
  }, [vote, enabled, disabled, minimumTimeToNotifyMS, minimumDelayBetweenNotifyMS])

  useEffect(() => cleanUp, [])
}
