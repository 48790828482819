import alertify from 'alertifyjs'
import { SOCKET_METHODS } from '../services'
import { useSocket } from './useSocket'


export function useNewTweetReadyAlert() {
  const onNewTweetReady = () => {
    alertify.notify('New Tweet is ready!', 'success', 0)
  }
  useSocket(SOCKET_METHODS.newTweetReady, (e) => e, onNewTweetReady)
}
