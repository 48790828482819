import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Nav } from 'react-bootstrap'

import ROUTES from '../config/routes'
import { NavTab } from './kit/NavTab'

export default function NavTabs() {
  const history = useHistory()
  const location = useLocation()

  const onSelect = useCallback(
    (link) => {
      history.push(`${link}`)
    },
    [history]
  )

  const tabs = useMemo(() => {
    return ROUTES.map(({ title, getRoute }, key) => {
      return <NavTab eventKey={getRoute()} title={title} key={key} />
    })
  }, [])

  return (
    <Nav
      variant='tabs'
      justify
      activeKey={location.pathname}
      onSelect={onSelect}
      className='mb-3'
    >
      {tabs}
    </Nav>
  )
}
