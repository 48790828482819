import * as Yup from 'yup'
import {
  getYoutubeLinkInfo,
  getTwitchLinkInfo,
  getTournamentIdInfo,
} from '../services/api.service'
import validator from 'validator'

const tooShortError = 'Too Short!'
const tooLongError = 'Too Long!'
const reqError = 'Required'
const ytError = 'Should be valit YT link'
const twError = 'Should be valit TW link'
const tornamentIdError = 'Wrong tournament id. Probably tournament doesnt exist'
const gameNumberError = 'Something wrong with gamenumber'
const tagsError =
  'Tags provided in wrong format. Check for whitespaces inside or some symbols'

Yup.addMethod(
  Yup.string,
  'urlString',
  function (errorMessage = 'Should be url') {
    return this.test(`url-string`, errorMessage, function (value) {
      const { path, createError } = this
      // console.log(this)
      return (
        (value && validator.isURL(value)) ||
        createError({ path, message: errorMessage })
      )
    })
  }
)
Yup.addMethod(Yup.string, 'validYTStream', function (errorMessage = ytError) {
  return this.test('valid-yt-stream', errorMessage, async function (value) {
    // const { isValid, videInfo, isOnline } = await getYoutubeLinkInfo(value)
    const { isValid } = await getYoutubeLinkInfo(value)
    // console.log(isValid, videInfo, isOnline)
    return isValid
  })
})
Yup.addMethod(Yup.string, 'validTWStream', function (errorMessage = twError) {
  return this.test('valid-tw-stream', errorMessage, async function (value) {
    // const { isValid, videInfo, isOnline } = await getTwitchLinkInfo(value)
    const { isValid } = await getTwitchLinkInfo(value)
    // console.log(isValid, videInfo, isOnline)
    return isValid
  })
})
Yup.addMethod(
  Yup.string,
  'validTournamentId',
  function (errorMessage = tornamentIdError) {
    return this.test(
      'valid-tournamentid',
      errorMessage,
      async function (value) {
        const { isValid } = await getTournamentIdInfo(value)
        // console.log(isValid)
        return isValid
      }
    )
  }
)
function getStritngsArraySchema(stringSchema) {
  return Yup.object().shape({
    strings: Yup.array().of(stringSchema).required(reqError),
  })
}
const streamUrlSchema = Yup.string().urlString().required(reqError)

const youtubeSchema = streamUrlSchema
  .min(20, tooShortError)
  .max(50, tooLongError)
  .validYTStream()
export const youtubeFormSchema = getStritngsArraySchema(youtubeSchema)

const twitchSchema = streamUrlSchema
  .min(9, tooShortError)
  .max(50, tooLongError)
  .validTWStream()
export const twitchFormSchema = getStritngsArraySchema(twitchSchema)

const tournamentIdSchema = Yup.string()
  .min(3, tooShortError)
  .max(20, tooLongError)
  .required(reqError)
  .validTournamentId()
export const tournamentIdFormScheme = getStritngsArraySchema(tournamentIdSchema)

Yup.addMethod(
  Yup.string,
  'validGameNumber',
  function (errorMessage = gameNumberError) {
    return this.test('valid-gamenumber', errorMessage, async function (value) {
      return true
    })
  }
)
const gameNumberSchema = Yup.string()
  .min(1, tooShortError)
  .max(300, tooLongError)
  .required(reqError)
  .validGameNumber()
export const gameNumberFormScheme = getStritngsArraySchema(gameNumberSchema)

function hasWhiteSpace(s) {
  return s.indexOf(' ') >= 0
}
Yup.addMethod(Yup.string, 'validTags', function (errorMessage = tagsError) {
  return this.test('valid-gamenumber', errorMessage, async function (value) {
    try {
      const trimmed = value.trim()
      const arr = trimmed.split(',')
      return arr.length > 0 && !hasWhiteSpace(trimmed)
    } catch (error) {
      return false
    }
  })
})
const voteTagsSchema = Yup.string()
  .min(1, tooShortError)
  .max(300, tooLongError)
  .required(reqError)
  .validTags()
export const voteTagsFormScheme = getStritngsArraySchema(voteTagsSchema)
