import { useEffect } from 'react'

export function useTitle(title) {
  useEffect(() => {
    const { title: oldTitle } = document
    document.title = title
    return () => {
      document.title = oldTitle
    }
  })
  return null
}
