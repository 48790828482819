export function getTitrosConfig() {
  return {
    sections: [
      {
        label: 'Аналитика',
        blocks: [
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Кран Дефолт',
                command: 'section1_crandefault',
                size: 3,
              },
            ],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Аналитика Рекап Лево',
                command: 'section1_analrecupleft',
                size: 3,
              },
              {
                label: 'Аналитика Рекап Право',
                command: 'section1_analrecupright',
                offset: 4,
                size: 3,
              },
            ],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Рекап Лево влет',
                command: 'section1_recupleftfly',
                size: 3,
              },
              {
                label: 'Рекап Право влет',
                command: 'section1_recuprightfly',
                offset: 4,
                size: 3,
              },
            ],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Рекап close-up',
                command: 'section1_recupcloseup',
                size: 3,
              },
            ],
          },
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Рекап Право влет',
                command: 'section1_recupcloseupfly',
                size: 3,
              },
            ],
          },
        ],
      },
      {
        label: 'Кастеры',
        blocks: [
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Кастеры дальний угол',
                command: 'section2_castersangle',
                size: 3,
              },
            ],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Кастеры представление',
                command: 'section2_castersentertainment',
                size: 3,
              },
            ],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Кастеры блендинг',
                command: 'section2_castersblending',
                size: 3,
              },
            ],
          },
          {
            type: '',
            buttons: [],
          },
        ],
      },
      {
        label: 'Реклама',
        blocks: [
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Телефон аналитики',
                command: 'section3_phone_anal',
                size: 3,
              },
            ],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: 'form',
            command: 'section3_зкувшсеы',
            submitLabel: 'ОБНОВИТЬ Предикты',
            fields: [
              {
                type: 'select',
                options: [
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                ],
                name: 'command1',
              },
              {
                type: 'select',
                options: [
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                ],
                name: 'command2',
              },
              {
                type: 'select',
                options: [
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                ],
                name: 'command3',
              },
            ],
          },
          {
            type: 'buttons',
            buttons: [
              {
                label: 'Предикты',
                command: 'section3_pridicts',
                size: 3,
              },
            ],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: '',
            buttons: [],
          },
          {
            type: 'form',
            command: 'section4_talantform',
            submitLabel: 'ОБНОВИТЬ',
            fields: [
              {
                type: 'input',
                placeholder: 'SuperlongNAME_1',
                name: 'SuperlongNAME_1',
                size: 6,
              },
              {
                type: 'input',
                placeholder: '@longINSTAGRAM_1',
                name: '@longINSTAGRAM_1',
                size: 6,
              },
              {
                type: 'input',
                placeholder: 'SuperlongNAME_2',
                name: 'SuperlongNAME_2',
                size: 6,
              },
              {
                type: 'input',
                placeholder: '@longINSTAGRAM_2',
                name: '@longINSTAGRAM_2',
                size: 6,
              },
            ],
          },
        ],
      },
    ],
  }
}
