import { useEffect, useState } from 'react'
import { disconnectSocket, initiateSocket, subscribe, unSubscribe } from '../services/socket.service'

export function useSocket(
  valuesChangeEventName,
  proccessResponse = (e) => e,
  onDataCallback = (data) => {},
  onValueCallback = (value) => {}
) {
  const [value, setValue] = useState()
  useEffect(() => {
    const proccessResponseInner = async (data) => {
      onDataCallback && onDataCallback(data)
      const obj = JSON.parse(data)
      if (!data) console.warn('smth wrong with data:', data)
      const value = proccessResponse ? proccessResponse(obj) : obj
      setValue(value)
      onValueCallback && onValueCallback(value)
    }
    initiateSocket()
    subscribe(valuesChangeEventName, proccessResponseInner)
    return () => {
      unSubscribe(valuesChangeEventName, proccessResponseInner)
      disconnectSocket()
    }
  }, [valuesChangeEventName, proccessResponse, onDataCallback, onValueCallback, setValue])

  return value
}
