import styled from 'styled-components'
import { Form } from 'react-bootstrap'

const StyledInputLabel = styled(Form.Label)``

export function InputLabel({ text = '', children, ...rest }) {
  return (
    <StyledInputLabel {...rest}>
      {text}
      {children}
    </StyledInputLabel>
  )
}
