import { useEffect, useState } from 'react'
import Spacer from '../kit/Spacer'
import { Button } from '../kit/Button'
import { useSocket } from '../../hooks/useSocket'
import { apiService, SOCKET_METHODS } from '../../services'
import { TweetsWrapper } from './TweetsWrapper'
import { syncTwitter } from '../../services/api.service'

export function TwitterService() {
  const [selectedTweets, setSelectedTweets] = useState([])
  const [usualTweets, setUsualTweets] = useState([])
  const [displayedTweets, setDisplayedTweets] = useState([])
  async function getTweetsInfo() {
    const { tweets } = await apiService.getTweets()
    // console.log(tweets)
    setSelectedTweets(tweets.filter((tw) => tw.isSelected))
    setDisplayedTweets(tweets.filter((tw) => tw.wasDisplayed))
    setUsualTweets(tweets.filter((tw) => !tw.wasDisplayed && !tw.isSelected))
  }
  async function setTweetSelectedOnServer(id, isSelected) {
    await apiService.setTweetSelected(id, isSelected)
  }
  function changeTweetSelection(index, changeStateTo = true, currentState) {
    if (changeStateTo === currentState) return
    // Move tweet from selected to usual or backwards
    const fromArray = changeStateTo ? usualTweets : selectedTweets
    const toArray = changeStateTo ? selectedTweets : usualTweets
    const setterTo = changeStateTo ? setSelectedTweets : setUsualTweets
    const setterFrom = changeStateTo ? setUsualTweets : setSelectedTweets
    const copyTo = [...toArray]
    const copyFrom = [...fromArray]
    copyFrom.splice(index, 1)
    // eslint-disable-next-line
    // console.log(fromArray, index);
    // eslint-disable-next-line
    const item = fromArray[index]
    item.isSelected = changeStateTo
    copyTo.push(item)
    setterTo(copyTo)
    setterFrom(copyFrom)
    setTweetSelectedOnServer(item.id, changeStateTo)
  }
  function moveSelectedToShowed() {
    const displayedTweetsNew = [...displayedTweets, ...selectedTweets]
    setDisplayedTweets(displayedTweetsNew)
    setSelectedTweets([])
  }

  useSocket(SOCKET_METHODS.tweetsStateChanged, (v) => v, getTweetsInfo)
  useSocket(SOCKET_METHODS.newTweetReady, (v) => v, getTweetsInfo)

  useEffect(() => {
    getTweetsInfo()
  }, [])

  return (
    <>
      <Button
        onClick={async () => {
          await syncTwitter()
        }}
        forseDisableDelay={3000}
      >
        Download tweets
      </Button>
      <Button
        onClick={async () => {
          await apiService.sendTweetsToApp()
          moveSelectedToShowed()
        }}
      >
        Send tweets to app
      </Button>
      <Spacer size={20} />
      <TweetsWrapper
        onTweetStateChanged={(isSelected, twid, index) => {
          changeTweetSelection(index, isSelected, true)
        }}
        tweets={selectedTweets}
        isSelectedGroup
        title='Selected tweets'
      />
      <Spacer size={20} />
      <TweetsWrapper
        onTweetStateChanged={(isSelected, twid, index) => {
          changeTweetSelection(index, isSelected, false)
        }}
        tweets={usualTweets}
        title='All tweets'
      />
      <Spacer size={20} />
      <TweetsWrapper tweets={displayedTweets} title='Used tweets' />
    </>
  )
}
