import styled from 'styled-components'
import { Form, InputGroup } from 'react-bootstrap'
import { Field } from 'formik'

const StyledFormSelect = styled(Form.Select)`
  color: ${({ theme }) => theme.colors.textInput};

  &::placeholder {
    color: ${({ theme }) => theme.colors.textInputPlaceholder};
  }

  &.disabled,
  &:disabled,
  &[readonly] {
    background-color: ${({ theme }) => theme.colors.textInputInactiveBg};
  }
`
const StyledSelectLabel = styled(InputGroup.Text)`
  color: ${({ theme }) => theme.colors.textInput};
`

export function SelectInput({
  name,
  defaultOption = '',
  label = '',
  disabled = false,
  canBeEmpty = false,
  options = [],
  ...rest
}) {
  const selectOptions = options.map((option, key) => (
    <option key={key} value={option}>
      {option}
    </option>
  ))

  const input = (
    <Field name={name} as={StyledFormSelect} {...rest}>
      {canBeEmpty && <option value="">Пусто</option>}
      {selectOptions}
    </Field>
  )

  const content = label ? (
    <InputGroup>
      <StyledSelectLabel>{label}</StyledSelectLabel>
      {input}
    </InputGroup>
  ) : (
    input
  )
  return <Form.Group>{content}</Form.Group>
}
