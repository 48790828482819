import React from 'react'
import { Formik, Form } from 'formik'
import { Col, Row } from 'react-bootstrap'

import { socketCommand } from '../../services/api.service'
import Spacer from '../kit/Spacer'
import { Button } from '../kit/Button'
import { TitroField } from './TitroField'

export function TitroForm({
  command,
  fields,
  submitLabel,
  onlyIfSelected,
  onSubmit = socketCommand,
  size = 12,
  offset = 0,
}) {
  const initialValues = {}
  fields.forEach((field) => {
    const { name, defaultOption, defaultValue } = field
    initialValues[name] = defaultOption || defaultValue || ''
  })
  // console.log(fields);
  return (
    <Formik
      initialValues={initialValues}
      // onSubmit={(values) => socketCommand({ command, values })}
      onSubmit={(values) => onSubmit({ command, values })}
    >
      {() => (
        <Col>
          <Form>
            <Row>
              {fields.map((fieldConfig, key) => (
                <TitroField key={key} {...fieldConfig} />
              ))}
            </Row>
            <Spacer size={10} />
            <Row>
              <Col xs={{ span: size, offset }}>
                <Button fluid submit type="third" color="primary">
                  {submitLabel}
                </Button>
              </Col>
            </Row>
          </Form>
          <Spacer size={16} />
        </Col>
      )}
    </Formik>
  )
}
