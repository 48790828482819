import styled from 'styled-components'
import { Nav } from 'react-bootstrap'

export const StyledNavTab = styled(Nav.Item)`
  color: ${({ theme }) => theme.colors.nav};
  background-color: ${({ theme }) => theme.colors.navBg};
  border-color: ${({ theme }) => theme.colors.navBorder};

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.navHover};
    background-color: ${({ theme }) => theme.colors.navBgHover};
    border-color: ${({ theme }) => theme.colors.navBorderHover};
  }
  &:focus {
    color: ${({ theme }) => theme.colors.navHover};
    background-color: ${({ theme }) => theme.colors.navBgHover};
    border-color: ${({ theme }) => theme.colors.navBorderHover};
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.navBgHover}50;
  }
  &:active:focus {
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme.colors.navBgHover}50;
  }
`

export const StyledNavLink = styled(Nav.Link)`
  color: ${({ theme }) => theme.colors.nav};

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.navHover}!important;
  }
`

// eventKey={getRoute()} title={title} key={key}
export function NavTab({ eventKey, title, ...rest }) {
  return (
    <StyledNavTab {...rest}>
      <StyledNavLink eventKey={eventKey}>{title}</StyledNavLink>
    </StyledNavTab>
  )
}
