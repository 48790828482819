import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { Logo } from '../Logo'

export default function Header({ children }) {
  return (
    <StyledContainer fluid>
      <StyledContainerInner>
        <Logo />
        <StyledHeaderInner>{children}</StyledHeaderInner>
      </StyledContainerInner>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  background-color: ${({ theme }) => theme.colors.header};
`
const StyledContainerInner = styled(Container)`
  display: flex;
  align-items: center;
  min-height: 48px;
  justify-content: flex-start;
`

const StyledHeaderInner = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
