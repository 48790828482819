import Spacer from '../kit/Spacer'
import { ReadonlyConfigurationWrapper } from './ReadonlyConfigurationWrapper'
import { TournamentIdConfig } from './TournamentIdConfig'
import { TwitchConfig } from './TwitchConfig'
import { YoutubeConfig } from './YoutubeConfig'

export function EventConfigurations() {
  return (
    <>
      <ReadonlyConfigurationWrapper label="Tournament ID" link="/configuration/tournament">
        <TournamentIdConfig readonly={true}></TournamentIdConfig>
      </ReadonlyConfigurationWrapper>
      <Spacer size={40} />
      <ReadonlyConfigurationWrapper label="YouTube" link="/configuration/youtube">
        <YoutubeConfig readonly={true}></YoutubeConfig>
      </ReadonlyConfigurationWrapper>
      <Spacer size={40} />
      <ReadonlyConfigurationWrapper label="Twitch" link="/configuration/twitch">
        <TwitchConfig readonly={true}></TwitchConfig>
      </ReadonlyConfigurationWrapper>
    </>
  )
}
