import styled from 'styled-components'
import dayjs from 'dayjs'

import Spacer from '../kit/Spacer'
import { getDurationTimeString } from '../../code/getDurationTimeString'
import { CheckboxInput } from '../kit/CheckboxInput'

const Footer = styled.div`
  width: 100%;
  padding: 24px;
  min-height: 100px;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  box-sizing: border-box;
  border-radius: 4px;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`
const StyledImage = styled.img`
  width: 100%;
  border-radius: 4px 4px 0 0;
`
const StyledTweet = styled.div`
  /* max-width: 350px; */
`

export function Tweet({
  onStateChanged,
  index,
  isSelected = false,
  tweet: { twid, fileName, createdAt },
}) {
  return (
    <StyledTweet>
      <StyledImage src={`/tweet/${fileName}`} />
      <Footer>
        <Header>
          {onStateChanged && (
            <>
              <CheckboxInput
                onChange={(e) => onStateChanged(isSelected, twid, index)}
                useFormik={false}
                checked={isSelected}
                isWrappedInGroup={false}
              />
              <Spacer size={10} />
            </>
          )}
          <p>{fileName}</p>
        </Header>
        <Spacer size={24} />
        <p>
          Added: {dayjs(createdAt).format('LL LTS')}
          <br />
          {getDurationTimeString(new Date() - new Date(createdAt))} назад
        </p>
      </Footer>
    </StyledTweet>
  )
}
