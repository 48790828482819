import React from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router'

import { ServiceNotFound } from '../components/services/ServiceNotFound'
import { ServicesList } from '../components/services/ServicesList'
import { VotesHistory } from '../components/services/VotesHistory'
import { CustomVoteResult } from '../components/services/CustomVoteResult'

function getContentByType(type) {
  switch (type) {
    case 'vote-history':
      return <VotesHistory />
    case 'custom-vote-result':
      return <CustomVoteResult />
    default:
      return null
  }
}

export function ServicesPage() {
  const { type } = useParams()
  const serviceContent = getContentByType(type)
  const content = serviceContent ? serviceContent : <ServicesList />
  const contentBase = type && !serviceContent ? <ServiceNotFound type={type} /> : content

  return <Container>{contentBase}</Container>
}


