import React from 'react'
import { Col } from 'react-bootstrap'

import Spacer from '../kit/Spacer'
import { SelectInput } from '../kit/SelectInput'
import { TextInput } from '../kit/TextInput'

export function TitroField({ type, size = 12, offset = 0, ...rest }) {
  let fieldWidget
  if (type === 'select') {
    fieldWidget = <SelectInput {...rest} />
  } else if (type === 'input') {
    fieldWidget = <TextInput {...rest} />
  }
  return (
    <Col xs={{ span: size, offset }}>
      {fieldWidget}
      <Spacer size={20} />
    </Col>
  )
}
