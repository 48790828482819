import { tournamentIdFormScheme } from '../../code/validators'
import { getTournamentId, setTournamentId } from '../../services/api.service'
import { SOCKET_METHODS } from '../../services/socket.service'
import { StringsConfigurationWidget } from './StringsConfigurationWidget'

export function TournamentIdConfig({ readonly, redirectAfterSubmition, submitButtonText = 'Save' }) {
  return (
    <StringsConfigurationWidget
      readonly={readonly}
      border={!readonly}
      redirectAfterSubmition={redirectAfterSubmition}
      isManagebleStringsCount={false}
      placeholder="TournamentId"
      validationSchema={tournamentIdFormScheme}
      submitButtonText={submitButtonText}
      valuesChangeEventName={SOCKET_METHODS.tournamentIdConfigurationChanged}
      handleSubmit={async (strings) => {
        // alert(strings)
        await setTournamentId(strings[0])
      }}
      getInitialValues={async () => {
        return await getTournamentId()
        // return ['3663']
      }}
      getValuesFromResponce={({ tournamentId }) => {
        // console.log(tournamentId)
        return [tournamentId || '']
      }}
    />
  )
}
