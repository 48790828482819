import styled from 'styled-components'
import * as bsIcons from 'react-icons/bs'

export function Icon({ type }) {
  if (!type) return null
  // eslint-disable-next-line
  const IconCompomnent = bsIcons[type]
  // console.log(IconCompomnent);
  if (!IconCompomnent) return null
  const StyledIcon = styled(IconCompomnent)`
    /* padding: 2px; */
  `
  return <StyledIcon />
}
