import React, { useEffect, useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'

import Spacer from './Spacer'
/* eslint-disable */
export function MyPagination({
  currentPage,
  totalPagesCount,
  pageClicked,
  children,
}) {
  const [pageArray, setPageArray] = useState([])

  useEffect(() => {
    let pageArr = []
    if (totalPagesCount > 1) {
      if (totalPagesCount <= 9) {
        let i = 1
        while (i <= totalPagesCount) {
          pageArr.push(i)
          i++
        }
      } else {
        if (currentPage <= 5) {
          pageArr = [1, 2, 3, 4, 5, 6, 7, 8, '', totalPagesCount]
        } else if (totalPagesCount - currentPage <= 4) {
          pageArr = [
            1,
            '',
            totalPagesCount - 7,
            totalPagesCount - 6,
            totalPagesCount - 5,
            totalPagesCount - 4,
            totalPagesCount - 3,
            totalPagesCount - 2,
            totalPagesCount - 1,
            totalPagesCount,
          ]
        } else {
          pageArr = [
            1,
            '',
            currentPage - 3,
            currentPage - 2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
            currentPage + 3,
            '',
            totalPagesCount,
          ]
        }
      }
    }
    setPageArray(pageArr)
  }, [currentPage, totalPagesCount])

  return (
    <React.Fragment>
      {children}
      <Spacer size={20} />
      <Pagination style={{ justifyContent: 'center' }}>
        {pageArray.map((el, key) => {
          const result = []

          if (key === 0) {
            result.push(
              <Pagination.First
                key={'firstpage'}
                onClick={
                  currentPage === 1
                    ? () => {}
                    : () => {
                        pageClicked(1)
                      }
                }
              />
            )

            result.push(
              <Pagination.Prev
                key={'prevpage'}
                onClick={
                  currentPage === 1
                    ? () => {}
                    : () => {
                        pageClicked(currentPage - 1)
                      }
                }
              />
            )
          }

          if (el === '') {
            result.push(<Pagination.Ellipsis key={key} />)
          } else {
            result.push(
              <Pagination.Item
                key={key}
                active={currentPage === el ? true : false}
                onClick={
                  currentPage === el
                    ? () => {}
                    : () => {
                        pageClicked(el)
                      }
                }
              >
                {el}
              </Pagination.Item>
            )
          }
          if (key === pageArray.length - 1) {
            result.push(
              <Pagination.Next
                key={'nextpage'}
                onClick={
                  currentPage === el
                    ? () => {}
                    : () => {
                        pageClicked(currentPage + 1)
                      }
                }
              />
            )

            result.push(
              <Pagination.Last
                key={'lastpage'}
                onClick={
                  currentPage === el
                    ? () => {}
                    : () => {
                        pageClicked(el)
                      }
                }
              />
            )
          }

          return result
        })}
      </Pagination>
    </React.Fragment>
  )
}
/* eslint-enable */