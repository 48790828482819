import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { InputLabel } from './InputLabel'
import { Field } from 'formik'

const StyledFormControl = styled(Form.Control)`
  color: ${({ theme }) => theme.colors.textInput};

  &::placeholder {
    color: ${({ theme }) => theme.colors.textInputPlaceholder};
  }

  &.disabled,
  &:disabled,
  &[readonly] {
    background-color: ${({ theme }) => theme.colors.textInputInactiveBg};
  }
`

export function TextInput({
  label = '',
  isWrappedInGroup = true,
  placeholder = 'placeholder',
  disabled = false,
  ...rest
}) {
  const content = (
    <>
      {label && <InputLabel text={label} />}
      <Field disabled={disabled} placeholder={placeholder} as={StyledFormControl} {...rest} />
    </>
  )
  const result = isWrappedInGroup ? <Form.Group>{content}</Form.Group> : content
  return result
}
