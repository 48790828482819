const defaultTheme = {
  colors: {
    //////////////
    // Main
    header: '#343A40',
    //
    //////////////

    //////////////
    // Buttons
    btnInactive: '#C4C8CB',
    btnInactiveBg: 'transparent',

    btnPrimary: '#FFFFFF',
    btnPrimaryHover: '#FFFFFF',
    btnPrimaryBg: '#28A745',
    btnPrimaryBgHover: '#367D46',

    btnSecondary: '#68717A',
    btnSecondaryHover: '#FFFFFF',
    btnSecondaryBg: 'transparent',
    btnSecondaryBgHover: '#808080',

    btnThird: '#FFFFFF',
    btnThirdHover: '#FFFFFF',
    btnThirdBg: '#7749f8',
    btnThirdBgHover: '#3C18A1',

    btnDanger: '#FFFFFF',
    btnDangerHover: '#FFFFFF',
    btnDangerBg: '#DC3545',
    btnDangerBgHover: '#950412',

    btnTransparent: '#FFFFFF',
    btnTransparentHover: '#000000',
    //
    //////////////

    //////////////
    // Inputs
    textInput: '#68717A',
    textInputPlaceholder: '#ABB5BE',
    textInputInactive: '#C4C8CB',
    textInputInactiveBg: '#e9ecef',
    //
    checkboxInputBg: '#28A745',
    checkboxInputInactiveBg: 'grey',
    checkboxInputFocusShadow: '#28A74540',
    checkboxInputFocusBorder: '#28A745',
    //
    //////////////

    //////////////
    // Info
    infoBg: '#F8F9FA',
    infoBorder: '#E9ECEF',
    //
    //////////////

    //////////////
    // Nav
    nav: '#7749F8',
    navHover: '#6C757D',
    navBg: 'transparent',
    navBgHover: 'transparent',
    navBorder: '#e9ecef #e9ecef #e9ecef',
    navBorderHover: '#B0B2B3 #B0B2B3 #B0B2B3',
    //
    //////////////
    heading: '#152536',
    white: '#FFFFFF',

    bgPrimary: '#E5E5E5',
    bgSecondary: '#343A40',
  },
}
const themes = { defaultTheme }

export default themes
