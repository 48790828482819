import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Spacer from '../kit/Spacer'
import { H2 } from '../kit/Headings'
import { TitroBlock } from './TitroBlock'

export function TitroSection({ size = 6, label = '', blocks = [], ...rest }) {
  return (
    <Col lg={size} {...rest}>
      {label && <H2>{label}</H2>}
      <Spacer size={16} />
      {blocks.map((blockConfig, key) => (
        <React.Fragment key={key}>
          <Row>
            <TitroBlock {...blockConfig} />
          </Row>
          <Spacer size={16} />
        </React.Fragment>
      ))}
    </Col>
  )
}
