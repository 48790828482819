import React, { useEffect, useState } from 'react'
import { H1 } from '../kit/Headings'
import Spacer from '../kit/Spacer'
import { getVoteHistory } from '../../services/api.service'
import { MyPagination } from '../kit/MyPagination'
import { VoteResult } from './VoteResult'

export function VotesHistory({ pageSize = 10 }) {
  const [votesHistory, setVotesHistory] = useState([])
  const [page, setPage] = useState(1)
  async function getHistory() {
    const history = await getVoteHistory()
    setVotesHistory(history)
  }
  useEffect(() => {
    getHistory()
  }, [])
  return (
    <>
      <H1>Vote history</H1>
      <Spacer size={20} />
      <MyPagination
        totalPagesCount={Math.ceil(votesHistory.length / pageSize)}
        currentPage={page}
        pageClicked={(pageNumber) => {
          setPage(pageNumber)
        }}
      >
        {votesHistory
          .slice(pageSize * (page - 1), pageSize * (page - 1) + pageSize)
          .map((voteState, key) => (
            <ul key={key}>
              <VoteResult
                voteState={voteState}
                displayHead
                displayStatus
                displayTags
              />
              <Spacer size={20} />
            </ul>
          ))}
      </MyPagination>
    </>
  )
}
