import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import { useSocket } from '../../hooks/useSocket'
import { StringsForm } from '../kit/StringsForm'

export function StringsConfigurationWidget({
  readonly,
  label = '',
  border = false,
  addButtonText = 'Add stream',
  submitButtonText = 'Next',
  isManagebleStringsCount = true,
  validationSchema,
  keepSaveVisibleIfReadonly = false,
  useTextAreas = false,
  placeholder = 'url',
  emptyLabel = '',
  valuesChangeEventName,
  redirectAfterSubmition = '',
  handleSubmit = async (values) => {
    alert(values)
  },
  getInitialValues = async () => {
    return ['']
  },
  getValuesFromResponce = (values) => {
    return values
  },
  children,
}) {
  const [values, setValues] = useState([])
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      const info = await getInitialValues()
      const values = getValuesFromResponce(info)
      console.log('gg', values)
      if (!values) console.warn('smth wrong with values')
      setValues(values || [])
    })()
  }, [getInitialValues, getValuesFromResponce])

  useSocket(
    valuesChangeEventName,
    getValuesFromResponce,
    (data) => {},
    (values) => setValues(values || [])
  )

  return (
    <StringsForm
      label={label}
      useTextAreas={useTextAreas}
      readonly={readonly}
      keepSaveVisibleIfReadonly={keepSaveVisibleIfReadonly}
      border={border}
      emptyLabel={emptyLabel}
      placeholder={placeholder}
      addButtonText={addButtonText}
      submitButtonText={submitButtonText}
      defaultValues={values}
      enableReinitialize
      isManagebleStringsCount={isManagebleStringsCount}
      validationSchema={validationSchema}
      handleSubmit={async (values) => {
        await handleSubmit(values)
        redirectAfterSubmition && history.push(redirectAfterSubmition)
      }}
    >
      {children}
    </StringsForm>
  )
}
