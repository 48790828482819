import styled from 'styled-components'

export const ButtonsBlock = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: ${({ isStreched }) =>
    isStreched ? 'space-between' : 'flex-start'};
  box-sizing: border-box;
  & > * {
    margin: 0 10px 5px 0;
  }
`
