import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router'
import { useQuery } from '../hooks'

export function FirstTimeConfiguration() {
  const [cookies] = useCookies(['setup'])
  const history = useHistory()
  const query = useQuery()
  useEffect(() => {
    if (!cookies['setup']) {
      query.set('show_next', true)
      history.push({
        pathname: '/configuration/youtube',
        search: `?${query.toString()}`,
      })
    }
    // eslint-disable-next-line
  }, [cookies])
  return null
}
