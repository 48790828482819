import { useHistory } from 'react-router'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'

import { useSearch } from '../../hooks'
import { Button } from '../kit/Button'
import { H1, H2 } from '../kit/Headings'
import Spacer from '../kit/Spacer'

export function ConfigurationWrapper({
  children,
  head1,
  head2,
  isShowNextButton = false,
  prevLink,
  nextLink,
  isLastLink,
  isFirstLink,
}) {
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['setup'])
  return (
    <>
      <H1>{head1}</H1>
      <Spacer size={24} />
      <H2>{head2}</H2>
      {children && <Spacer size={48} />}
      {children}
      {isShowNextButton && (
        <>
          <Spacer size={32} />
          <PrevNextButtons
            prevLink={prevLink}
            nextLink={nextLink}
            disableBackButton={isFirstLink}
            clearNextLinkQuery={isLastLink}
            onLastLinkClicked={() => {
              setCookie('setup', '1')
            }}
          />
        </>
      )}
    </>
  )
}

const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

function PrevNextButtons({
  prevLink,
  nextLink,
  disableBackButton = false,
  disableNextButton = false,
  clearNextLinkQuery = false,
  onLastLinkClicked = () => {},
}) {
  const history = useHistory()
  const search = useSearch()
  return (
    <>
      <StyledButtonsWrapper>
        {prevLink && (
          <Button
            disabled={disableBackButton}
            type="secondary"
            onClick={() => history.push({ pathname: prevLink, search })}
          >
            Back
          </Button>
        )}
        {nextLink && (
          <Button
            disabled={disableNextButton}
            type="secondary"
            onClick={() => {
              onLastLinkClicked && onLastLinkClicked()
              history.push({ pathname: nextLink, search: clearNextLinkQuery ? '' : search })
            }}
          >
            Skip
          </Button>
        )}
      </StyledButtonsWrapper>
    </>
  )
}
