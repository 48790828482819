import axios from 'axios'

const API_URL = '/v1/auth/'

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + 'login', {
        email,
        password,
      })
      .then((response) => {
        const {
          data: { user, tokens },
        } = response

        if (tokens && user && tokens.access) {
          console.log(user, tokens)
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('tokens', JSON.stringify(tokens))
        }

        return { user, tokens }
      })
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('tokens')
  }

  register(name, email, password) {
    return axios.post(API_URL + 'register', {
      name,
      email,
      password,
    })
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'))
  }
}

export default new AuthService()
