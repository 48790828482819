import React from 'react'
import { useTitle } from '../hooks'

/*
 * Component which serves the purpose of a "root route component".
 */
export function Page({ component: PageComponent, title }) {
  useTitle(title)
  return <PageComponent />
}
